import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
// import { windowScroll } from '../utils/windowScroll'
import { FaWifi } from 'react-icons/fa'
import { MdLiveTv } from 'react-icons/md'
import ImageIcons from '../imageComponent/ImageIcons';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { windowScroll } from '../../components/utils/windowScroll';




const WeAreLocated = () => {
  const [data, setData] = useState([])
  const [data2, setData2] = useState([])
// const [allPlan, setAllPlan] = useState(Plans)
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URL}user/getcities`);
        setData(response?.data?.data);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  const handleCityChange = (event) => {
    const selectedCityHandle = event.target.value;
    if (selectedCityHandle) {
      navigate(`/cities/${selectedCityHandle}`);
    }
    windowScroll();

  };


  const [data3, setData3] = useState([]);
  console.log("data3>>>.", data3)

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}admin/getPackages`);
      console.log("response>>>", response)
      const filteredData = response?.data?.data?.filter(item => item.typeid.type === "Residental");
      console.log("filteredData", filteredData)
      setData3(filteredData);
      const filteredData1 = response?.data?.data?.filter(item => item.typeid.type === "Commercial");
      console.log("filtered", filteredData1)
      setData2(filteredData1);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className="relative">
      <div className='container'>
        <h2 className='text-[#f48533] text-center'>Your Binge-Worthy Broadband Experience Is Here</h2>
        <div className='flex max-sm:flex-col items-center gap-4 justify-center pt-[10px]'>
          <div>Tell us where you live</div>
          <select onChange={handleCityChange}
            className='w-[260px] h-[50px] border border-[#f7dfcd] bg-[#f4853308] px-[15px] rounded-[5px] focus:outline-none focus:border-[#fdceaa]'>
            <option value="">Select City</option>
            {data?.map((item, i) => (
              <option value={item?.handle} >{item?.cityname}</option>
            ))}
          </select>
        </div>

        <h2 className='mb-0 text-center mt-[20px] uppercase text-[25px]'>Home Internet Plans</h2>
        <div className='grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] mt-[40px]'>
          {data3?.map((item, i) => (
            <div key={i} className='border border-[#f48533] rounded-[10px]'>
              <div className='text-center p-[20px]'>
                <div className='mb-2 text-[#f48533]'>Upto</div>
                <h2 className='mb-[0]'>{item?.speedid?.speed}</h2>
              </div>
              <div className='p-[30px] bg-[#f48533] mb-[20px]' style={{ clipPath: 'polygon(0% 0%, 101% 0%, 100% 80%, 50% 100%, 0% 80%)' }}>
                <ul className="flex items-center justify-center gap-[40px]">
                  <li className='flex items-center flex-col relative after:last:hidden after:content-["+"] after:absolute after:font-bold after:text-[25px] after:text-[white] after:ml-[12.5px] after:left-full after:top-0'>
                    <span className='w-[40px] h-[40px] bg-[#fff] flex items-center justify-center rounded-full mb-[10px]'><FaWifi /></span>
                    <strong className='text-white'>Wi-Fi</strong>
                  </li>
                  <li className='flex items-center flex-col relative after:last:hidden after:content-["+"] after:absolute after:font-bold after:text-[25px] after:text-[white] after:ml-[12.5px] after:left-full after:top-0'>
                    <span className='w-[40px] h-[40px] bg-[#fff] flex items-center justify-center rounded-full mb-[10px]'><MdLiveTv /></span>
                    <strong className='text-white'>OTT</strong>
                  </li>
                  <li className='flex items-center flex-col relative after:last:hidden after:content-["+"] after:absolute after:font-bold after:text-[25px] after:text-[white] after:ml-[12.5px] after:left-full after:top-0'>
                    <span className='w-[40px] h-[40px] bg-[#fff] flex items-center justify-center rounded-full mb-[10px]'><MdLiveTv /></span>
                    <strong className='text-white'>IPTV</strong>
                  </li>
                </ul>
              </div>


              <div className='xxl:px-[30px] px-[20px] mb-[20px] '>
                <div className='flex items-center justify-between border-b border-dashed border-[#f7dfcd] pb-2 mb-2 text-[#f48533]'>
                  <div>{item?.onemonth}</div>
                  <h3 className='mb-0 xxl:text-[25px] lg:text-[22px] text-[20px]'>{item?.pricemonthsPriceThree}
                    {/* <span className='text-[20px]'>mo*</span> */}
                  </h3>
                </div>
                <div className='flex items-center justify-between border-b border-dashed border-[#f7dfcd] pb-2 mb-2'>
                  <div>{item?.sixmonth}</div>
                  <h3 className='mb-0 xxl:text-[25px] lg:text-[22px] text-[20px]'>{item?.monthsPriceSix}
                    {/* <span className='text-[20px]'>mo*</span> */}
                  </h3>
                </div>
                <div className='flex items-center justify-between'>
                  <div>{item?.twelvemonth}</div>
                  <h3 className='mb-0 xxl:text-[25px] lg:text-[22px] text-[20px]'>{item?.monthsPriceTwelve}
                    {/* <span className='text-[20px]'>mo*</span> */}
                  </h3>
                </div>
              </div>

              <div className='text-center px-[20px]'>
                <div>TV Channels</div>
                <ul>
                  <li className='w-[50px] h-[50px]'><img src={ImageIcons.myinternetBanner} alt='' /></li>
                </ul>
              </div>

              <div className='text-center mb-[30px]'>
                <Link className='mx-auto px-[25px] py-[12px] font-medium text-[#fff] bg-[#f48533] rounded-[10px] hover:bg-[#000] ease inline-block' to='/get-myinternet' onClick={windowScroll}>Buy Now</Link>
              </div>
            </div>
          ))}
        </div>
        <h2 className='mb-0 text-center mt-[40px] uppercase text-[25px]'>Business Internet Plans</h2>
        <div className='grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] mt-[40px]'>
          {data2?.map((item, i) => (
            <div key={i} className='border border-[#f48533] rounded-[10px]'>
              <div className='text-center p-[20px]'>
                <div className='mb-2 text-[#f48533]'>Upto</div>
                <h2 className='mb-[0]'>{item?.speedid?.speed}</h2>
              </div>
              <div className='p-[30px] bg-[#f48533] mb-[20px]' style={{ clipPath: 'polygon(0% 0%, 101% 0%, 100% 80%, 50% 100%, 0% 80%)' }}>
                <ul className="flex items-center justify-center gap-[40px]">
                  <li className='flex items-center flex-col relative after:last:hidden after:content-["+"] after:absolute after:font-bold after:text-[25px] after:text-[white] after:ml-[12.5px] after:left-full after:top-0'>
                    <span className='w-[40px] h-[40px] bg-[#fff] flex items-center justify-center rounded-full mb-[10px]'><FaWifi /></span>
                    <strong className='text-white'>Wi-Fi</strong>
                  </li>
                  <li className='flex items-center flex-col relative after:last:hidden after:content-["+"] after:absolute after:font-bold after:text-[25px] after:text-[white] after:ml-[12.5px] after:left-full after:top-0'>
                    <span className='w-[40px] h-[40px] bg-[#fff] flex items-center justify-center rounded-full mb-[10px]'><MdLiveTv /></span>
                    <strong className='text-white'>OTT</strong>
                  </li>
                  <li className='flex items-center flex-col relative after:last:hidden after:content-["+"] after:absolute after:font-bold after:text-[25px] after:text-[white] after:ml-[12.5px] after:left-full after:top-0'>
                    <span className='w-[40px] h-[40px] bg-[#fff] flex items-center justify-center rounded-full mb-[10px]'><MdLiveTv /></span>
                    <strong className='text-white'>IPTV</strong>
                  </li>
                </ul>
              </div>


              <div className='xxl:px-[30px] px-[20px] mb-[20px] '>
                <div className='flex items-center justify-between border-b border-dashed border-[#f7dfcd] pb-2 mb-2 text-[#f48533]'>
                  <div>{item?.onemonth}</div>
                  <h3 className='mb-0 xxl:text-[25px] lg:text-[22px] text-[20px]'>{item?.pricemonthsPriceThree}
                    {/* <span className='text-[20px]'>mo*</span> */}
                  </h3>
                </div>
                <div className='flex items-center justify-between border-b border-dashed border-[#f7dfcd] pb-2 mb-2'>
                  <div>{item?.sixmonth}</div>
                  <h3 className='mb-0 xxl:text-[25px] lg:text-[22px] text-[20px]'>{item?.monthsPriceSix}
                    {/* <span className='text-[20px]'>mo*</span> */}
                  </h3>
                </div>
                <div className='flex items-center justify-between'>
                  <div>{item?.twelvemonth}</div>
                  <h3 className='mb-0 xxl:text-[25px] lg:text-[22px] text-[20px]'>{item?.monthsPriceTwelve}
                    {/* <span className='text-[20px]'>mo*</span> */}
                  </h3>
                </div>
              </div>

              <div className='text-center px-[20px]'>
                <div className='mb-[10px]'>TV Channels</div>
                <ul className='flex items-center mb-[20px]'>
                  <li className='w-[50px] h-[50px] border border-[#f48533] rounded-full overflow-hidden '><img className='w-full h-full object-cover' src={ImageIcons.myinternetBanner} alt='' /></li>
                  <li className='w-[50px] h-[50px] border border-[#f48533] rounded-full overflow-hidden -ml-[20px]'><img className='w-full h-full object-cover' src={ImageIcons.myinternetBanner} alt='' /></li>
                  <li className='w-[50px] h-[50px] border border-[#f48533] rounded-full overflow-hidden -ml-[20px]'><img className='w-full h-full object-cover' src={ImageIcons.myinternetBanner} alt='' /></li>
                  <li className='w-[50px] h-[50px] border border-[#f48533] rounded-full overflow-hidden -ml-[20px]'><img className='w-full h-full object-cover' src={ImageIcons.myinternetBanner} alt='' /></li>
                  <li className='w-[50px] h-[50px] border border-[#f48533] rounded-full overflow-hidden -ml-[20px] flex items-center justify-center bg-white'>+56</li>
                  {/* {item.addData.map((item, i) => (
                        <li key={i} className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' /> {item}</li>
                      ))} */}
                      </ul>
              </div>
               <div>
                {/* <ol> 
                <li><IoCheckmarkDoneSharp className='inline-block text-[#f48533] mr-[5px] ml-[15px]'/>{item}</li>
                </ol> */}
                <ul >
                      {item?.addData?.map((item, i) => (
                        <li key={i} className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' /> {item}</li>
                      ))}
                    </ul>
              </div>

              <div className='text-center mb-[30px]'>
                <Link className='mx-auto px-[25px] py-[12px] font-medium text-[#fff] bg-[#f48533] rounded-[10px] hover:bg-[#000] ease inline-block' to='/get-myinternet' onClick={windowScroll}>Buy Now</Link>
              </div>
            </div>

          ))}
        </div>
      </div>
    </section>
  )
}

// const Plans = [
//   {
//     title:"Data - Umlimited"
//   },
//   {
//     title:"Static IP"
//   },
//   {
//     title:"Hosting",
//   },
//   {
//     title:"Website",
   
//   },
//   {
//     title:"Cloud Surveillance",
   
//   }
// ]

export default WeAreLocated