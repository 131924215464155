import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiUser } from "react-icons/fi";
import { FaRegCalendarAlt } from "react-icons/fa";
import { RiArrowRightLine } from "react-icons/ri";
import { windowScroll } from "../utils/windowScroll";
import axios from 'axios';

const Blogs = () => {
  const [allBlogs, setAllBlogs] = useState([]);

  const removeHTMLTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  const fetchBlogs = async () => {
    const response = await axios.get(`${process.env.REACT_APP_URL}user/getblog`);
    const filteredData = response.data.data.filter(item => item.isActive === true);
    const sortedBlogs = filteredData.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));
    const latest4Blogs = sortedBlogs.slice(0, 4);
    console.log("latest4Blogs", latest4Blogs)
    setAllBlogs(latest4Blogs);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (

    <section className='pt-0'>
      <div className="container">
        <div className='max-w-[800px] md:mb-[50px] mb-[40px] text-center mx-auto'>
          <h2>Our <span className='text-[#f48533]'>Recent</span> Update</h2>
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[30px] sm:gap-[20px] lg:gap-[20px] '>
          {allBlogs.map((item, i) => (
            <Link key={i} to={`/blogs/${item.handle}`} onClick={windowScroll} className='flex flex-col overflow-hidden group border rounded-[10px] duration-500 shadow-md hover:shadow-[0px_4px_25px_rgba(0,0,0,0.12)] justify-start'>
              <div className='relative xl:min-h-[300px] min-h-[230px] w-full overflow-hidden group-hover:after:absolute after:content-[""] after:w-full after:h-full after:left-0 after:top-0  after:bg-[linear-gradient(157deg,#f48533_0%,#3308d3_100%)] after:duration-500 after:opacity-50'>
                <img
                  src={item?.image}
                  alt={item?.heading}
                  className='w-full h-full object-center object-cover duration-500 group-hover:rotate-[5deg] group-hover:scale-150 '
                />
              </div>
              <div className='p-[25px]'>
                <ul className='mb-[20px] flex items-center'>
                  <li className='flex items-center text-[15px] mr-[15px]'><FiUser className='inline-block text-[#f48533] mr-[8px]' /> {item?.author}</li>
                  <li className='flex items-center text-[15px]'><FaRegCalendarAlt className='inline-block text-[#f48533] mr-[8px]' /> {item?.publishedAt}</li>
                </ul>
                <div>
                  <h4 className='text-[20px] mb-[15px] group-hover:text-[#f48533] duration-500 font-semibold leading-[28px]'>{item?.title}</h4>
                  <p className='mb-[15px] relative line-clamp-3 '>{removeHTMLTags(item?.description)}</p>
                  <span className='text-[#000] group-hover:text-[#f48533] duration-500'>Read More <RiArrowRightLine className='inline-block' /></span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>

  )
};

export default Blogs
